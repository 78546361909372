<template>
    <div class="h-100">
        <portal to="layout-default-header">
            <header-index :title="isAdmin ? $t('posts.index.title') : $t('posts.index.titleMe')">
                <input-dd-media-type
                    v-model="collection.types"
                    class="btn-min-width mr-3"
                    @input="refetchCollection()"
                />

                <input-search
                    v-model="collection.searchQuery"
                    class="mr-3"
                />

                <template #create-new>
                    <b-btn
                        :to="{name: 'posts.create'}"
                        variant="dark"
                        class="btn-min-width"
                    >
                        {{ $t('posts.actions.uploadMedia') }}
                    </b-btn>
                </template>
            </header-index>
        </portal>

        <div
            v-if="!collection.loading && collection.isEmpty()"
            class="h-100 d-flex flex-column justify-content-center align-items-center"
        >
            <h5>{{ $t('posts.messages.uploadMediaAndAddToPrograms') }}</h5>

            <b-link
                :to="{name: 'posts.create'}"
                class="text-primary mt-2"
            >
                {{ $t('posts.actions.uploadMedia') }}
            </b-link>
        </div>

        <!-- Grid of Posts -->
        <grid-list
            v-else
            v-infinite-scroll.document="fetchCollection"
            :items="collection.models"
            cols="6"
            md="4"
            lg="3"
        >
            <template #default="{item}">
                <media-card
                    :post="item"
                    class="cursor-pointer bg-white border"
                    @click.native="$router.push(item.getLocation())"
                />
            </template>
        </grid-list>

        <!-- Loader -->
        <wait-for-resource :resource="collection" />
    </div>
</template>

<script>
import {Posts} from '@/models/Post';
import GridList from '@/components/common/GridList';
import MediaCard from '@/components/media/MediaCard';
import PostType from '@/library/enumerations/PostType';
import Role from '@/library/enumerations/Role';
import HeaderIndex from '@/components/layouts/HeaderIndex';
import InputSearch from '@/components/common/form/InputSearch';
import QueryableCollection from '@/components/common/mixins/QueryableCollection';
import InputDdMediaType from '@/components/common/form/InputDropdownMediaType';

export default {
    name: 'PostsIndex',
    components: {GridList, MediaCard, HeaderIndex, InputSearch, InputDdMediaType},
    mixins: [QueryableCollection],
    data: function() {
        return {
            collection: new Posts,
        };
    },
    computed: {
        isAdmin() {
            return this.$me.hasRoles(Role.ADMIN);
        },
        collectionOptions() {
            return this.isAdmin
                ? {}
                : {routePrefix: this.$me.getFetchURL()};
        },
    },
    async mounted() {
        // Fetch only media posts.
        this.collection.types = PostType.mediaTypes;

        this.collection.setOptions(this.collectionOptions);

        await this.fetchCollectionUntilScrollable();
    },
    methods: {
        async refetchCollection() {
            this.resetCollection();

            await this.fetchCollectionUntilScrollable();
        },
    },
};
</script>
