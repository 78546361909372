<template>
    <b-dd
        v-bind="$attrs"
        :text="text"
        v-on="$listeners"
    >
        <b-dd-item
            v-for="(item, index) in items"
            :key="`${item.label}-${index}`"
            :active="item.value === value"
            @click="emitInput(item)"
        >
            {{ item.label }}
        </b-dd-item>
    </b-dd>
</template>

<script>
import i18next from 'i18next';
import {isEqual} from 'lodash';

/**
 * A single value input component with an array of dropdown items to choose.
 *
 * Emit events:
 * - input(item) -> When the value has changed
 */
export default {
    name: 'InputDropdown',
    props: {
        items: {
            type: Array, // Array of {label: '', value: ''}.
            default: () => [],
        },
        placeholder: {
            type: String,
            default: i18next.t('inputs.dropdown.placeholder'),
        },
        value: { // eslint-disable-line vue/require-prop-types
            default: undefined,
        },
        allowDeselect: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        text() {
            const found = this.items.find(i => isEqual(i.value, this.value));

            return found !== undefined ? found.label : this.placeholder;
        },
    },
    methods: {
        emitInput(item) {
            const equal = isEqual(item.value, this.value);

            // If user click the currently selected value & deselection is allowed, emit undefined
            // Else if it's not the currently selected value emit the new value
            if (this.allowDeselect && equal) {
                this.$emit('input', undefined);
            } else if (!equal) {
                this.$emit('input', item.value);
            }
        },
    },
};
</script>
