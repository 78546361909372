<template>
    <input-dd
        :value="value"
        :items="items"
        :block="block"
        :variant="variant"
        @input="$emit('input', $event)"
    />
</template>

<script>
import PostType from '@/library/enumerations/PostType';
import InputDd from '@/components/common/InputDropdown';

/**
 * The input dropdown component that provides media type options to choose:
 * - Everything (Video, Audio)
 * - Video
 * - Audio
 */
export default {
    name: 'InputDropdownMediaType',
    components: {InputDd},
    props: {
        value: {
            type: Array,
            default: () => PostType.mediaTypes,
        },
        variant: {
            type: String,
            default: 'light',
        },
        /**
         * Indicates if the dropdown should span across parent's width.
         */
        block: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            /**
             * An array of media types as the dropdown items.
             */
            items: [
                {
                    label: this.$t('common.words.everything'),
                    value: PostType.mediaTypes,
                },
                ...PostType.toDropdownItems(PostType.mediaTypes)
                    // Map value to array to stay consistent with the
                    // "everything" item.
                    .map(i => {
                        i.value = [i.value];

                        return i;
                    }),
            ],
        };
    },
};
</script>
